@import '../../styles/vars.less';

.Login {
  background: linear-gradient(180deg, #ffa576, #ffab80 100%, #ff9964 100%);

  .pic {
    float: left;
    width: 540px;
    height: 100vh;
    background: url('../../assets/images/login_pic.png') no-repeat center bottom;
  }

  .form {
    position: relative;
    background: #fff;
    height: 100vh;
    margin-left: 540px;
    overflow-y: auto;

    .inner {
      position: absolute;
      top: 50%;
      left: 190px;
      transform: translateY(-50%);
      width: 518px;
      // margin: 0 0 0 190px;
      // padding-top: 140px;

      p {
        margin: 0;
        color: #000;
        font-size: 26px;

        & + p {
          margin-top: 16px;
          color: @orange;
          font-size: 20px;
        }
      }

      .ant-form-item {
        position: relative;
      }

      .ant-form-item-label {
        position: absolute;
        top: 16px;
        left: 24px;
        z-index: 11;

        label {
          color: #858b9c;
        }
      }

      .ant-input-affix-wrapper {
        padding: 0;
        border: none;

        &:focus,
        &-focused {
          box-shadow: none;
        }

        .ant-input-suffix {
          position: absolute;
          right: 24px;
          bottom: 24px;

          svg {
            color: #c6cad4;
          }
        }
      }

      .ant-input {
        padding: 55px 24px 19px;
        border: 1px solid #c5cad5;
        border-radius: 12px;
        box-shadow: 0 0 0 1000px #fff inset;

        &:focus {
          border: 2px solid @orange;
          box-shadow: 0 0 0 1000px #fef5f0 inset,
            0px 0px 6px 0px rgba(255, 133, 69, 0.62) !important;
        }
      }

      .username {
        margin-top: 46px;

        .ant-input {
          padding: 55px 24px 19px;
          border: 1px solid #c5cad5;
          border-radius: 12px;
          box-shadow: 0 0 0 1000px #fff inset;

          &:focus {
            border: 2px solid @orange;
            box-shadow: 0 0 0 1000px #fef5f0 inset,
              0px 0px 6px 0px rgba(255, 133, 69, 0.62);
          }
        }
      }

      .captcha {
        position: relative;

        .ant-form-item {
          margin: 0;
        }

        img {
          position: absolute;
          right: 0;
          bottom: 12px;
          z-index: 11;
          cursor: pointer;
        }
      }

      .submit {
        display: block;
        width: 100%;
        height: 60px;
        font-size: 16px;
        border-radius: 12px;
        box-shadow: 0px 4px 4px 0px rgba(255, 126, 59, 0.42);
      }
    }
  }

  .icp {
    color: #858b9c;
    font-size: 12px;

    a {
      color: #858b9c;
    }
  }
}

@primary-color: #ff7e3b;@text-color: #4f4f4f;